<template>
  <div class="profile">
    <div v-if="user" class="profile__wrapper" @click.stop="openDropdown">
      <div class="profile__cmn">
        <div v-if="user.firstName" class="profile__email">{{ user.email }}</div>
        <div class="profile__name">{{ name }}</div>
      </div>
      <div class="profile__ava">
        <img :src="avatar" />
      </div>
    </div>

    <div
      v-if="dropdown"
      v-click-outside="closeDropdown"
      class="dropdown"
    >
      <ul>
        <li>
          <router-link class="link" :to="{ name: 'settings-profile' }">
            <div class="link__icon">
              <IconSettings></IconSettings>
            </div>
            <div class="link__name">{{ $t('Profile settings') }}</div>
          </router-link>

<!--          <div class="link" @click="setTheme">-->
<!--            <div class="link__icon">-->
<!--              <IconMoon />-->
<!--            </div>-->
<!--            <div class="link__wrapper">-->
<!--              <div class="link__name">{{ $t('Dark mode') }}</div>-->
<!--              <SwitchUI v-model="themeIsDark" :inline="true" />-->
<!--            </div>-->
<!--          </div>-->
          <div class="link" @click="logout">
            <div class="link__icon">
              <IconExit />
            </div>
            <div class="link__name">{{ $t('logout') }}</div>
          </div>
        </li>
      </ul>

      <ul>
        <li>
          <MenuLanguage />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MenuLanguage from '@/components/menu/MenuLanguage'
import SwitchUI from '@/components/ui/SwitchUI'
import IconSettings from '@/assets/icons/settings2.svg'
import IconKeyboard from '@/assets/icons/keyboard.svg'
import IconMoon from '@/assets/icons/moon.svg'
import IconBulb from '@/assets/icons/bulb.svg'
import IconExit from '@/assets/icons/exit.svg'
import IconHelp from '@/assets/icons/help.svg'

export default {
  name: 'HeaderProfile',
  components: {
    MenuLanguage,
    SwitchUI,
    IconSettings,
    IconKeyboard,
    IconMoon,
    IconBulb,
    IconExit,
    IconHelp,
  },

  data: () => ({
    dropdown: false,
  }),
  computed: {
    ...mapState('user', [
      'user',
    ]),

    ...mapState('general', [
      'no_image',
    ]),

    name () {
      return this.user.firstName
        ? `${this.user.firstName} ${this.user.lastName ? `${this.user.lastName.substr(0, 1)}.` : ''}`
        : this.user.email
    },
    avatar () {
      return this.user.logoLink || this.no_image
    },
    themeIsDark () {
      return this.user.theme === 'dark'
    },
    localeCode () {
      return this.$i18n.locale.split('-')[0]
    },
    resultHelpLink () {
      return this.$i18n.locale === 'en'
        ? 'https://weeek.net/help'
        : `https://weeek.net/${this.localeCode}/help`
    },
  },
  watch: {
    $route () {
      this.closeDropdown()
    },
    user () {
      // console.log(this.user)
    },
  },

  methods: {
    ...mapActions('auth', [
      'logout',
    ]),

    ...mapActions('user', [
      'changeTheme',
    ]),

    setTheme () {
      this.closeDropdown()
      // this.changeTheme(this.user.theme === 'dark' ? 'light' : 'dark')
    },

    openDropdown () {
      this.dropdown = true
    },
    closeDropdown () {
      this.dropdown = false
    },
  },

  i18n: {
    messages: {
      'en': {
        settings: 'Settings',
        'Profile settings': 'Profile settings',
        Suggestions: 'Suggestions',
        Hotkeys: 'Hotkeys',
        Help: 'Help',
        logout: 'Log Out',
        'Dark mode': 'Dark mode',
      },
      'ru': {
        settings: 'Настройки',
        'Profile settings': 'Настройки',
        Suggestions: 'Предложения',
        Hotkeys: 'Горячие клавиши',
        Help: 'Помощь',
        logout: 'Выйти',
        'Dark mode': 'Тёмная тема',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.dark-theme {
  .profile {
    .dropdown {
      box-shadow: 0 6px 13px 0 rgba(25, 25, 25, 0.48);
      background: $darkThemeBgTwo;

      ul {
        border-bottom: 1px solid $darkThemeBgThree;

        li {
          .link {
            color: $darkThemeTextColor;

            svg {
              path {
                &:nth-child(2) {
                  fill: $darkThemeIconGrey
                }

                &:nth-child(1) {
                  fill: $darkThemeIconLightGrey;
                }
              }
            }

            &:hover {
              background: $darkThemeBgOne;
              color: #cccccc;

              svg {
                path {
                  &:nth-child(2) {
                    fill: $darkThemeBlue
                  }

                  &:nth-child(1) {
                    fill: $darkThemeLightBlue;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__name {
      color: $darkThemeTextLight;
    }

    &__ava {
      border-color: #2e2e2e;
      background: #2e2e2e;
    }
  }
}

.profile {
  padding-left: 10px;

  &__wrapper {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $dark;
    position: relative;
    cursor: pointer;
  }

  .dropdown {
    position: absolute;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 0 13px 0 rgba(48, 48, 48, 0.27);
    width: 185px;
    top: 100%;
    right: 20px;
    margin-top: 5px;
    cursor: default;

    ul {
      padding: 10px 0;
      margin: 0;
      border-bottom: 1px solid #f0f0f0;

      li {
        list-style: none;

        .link {
          padding: 0 15px;
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 33px;
          cursor: pointer;
          text-decoration: none;
          color: #2c2c2c;
          $root: &;

          &:hover {
            background: #f0f0f0;

            svg {
              path {
                &:nth-child(2) {
                  fill: $blue
                }

                &:nth-child(1) {
                  fill: $lightBlue;
                }
              }
            }
          }

          &__icon {
            width: 13px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;

            svg {
              width: 13px;
              height: 13px;
              fill: $grey;
              display: block;
            }
          }

          &__wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1 1 auto;
          }
        }
      }
    }
  }

  &__cmn {
    flex: none;
    margin-right: 10px;
  }

  &__name {
    font-size: rem(14px);
    font-weight: 500;
    text-align: right;
  }

  &__email {
    color: #8d8d8d;
    font-size: 10px;
    // font-weight: 300;
  }

  &__settings {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #768082;
    font-size: rem(12px);
    margin-top: 2px;
  }

  &__icon {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    flex: none;

    svg {
      fill: #768082;
      width: 100%;
      display: block;
    }
  }

  &__ava {
    border: 1px solid rgb(223, 223, 223);
    background: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    flex: none;

    img {
      display: block;
      width: 100%;
      border-radius: 50%;
    }
  }
}
</style>
