<template>
  <header class="header js-header">
    <div class="header__side">
<!--      <HeaderWorkspace />
      <HeaderProject v-if="currentPageIsProjectPage" />-->

      <div class="header__title">
        <router-link :to="{ name: 'Index'}">
          <img
            class="logo"
            src="@/assets/icons/icon.png"
            width="50"
            height="50"
          />
        </router-link>
      </div>

<!--      <Bonus v-if="!currentPageIsProjectPage" />-->

    </div>

    <div class="header__side header__side_right">
      <HeaderProfile />
      <TheBurgerMenu style="z-index: 11;"
                     v-show="isMobile"
      />
    </div>
  </header>
</template>

<script>
import { currentPageIsProjectPage } from '@/mixins/views/projectView'
import { currentWorkspace } from '@/mixins/workspace'
import VButton from '@/components/ui/VButton'
import HeaderProfile from '@/components/header/HeaderProfile'
import ChangeServiceIcon from '@/components/changeService/ChangeServiceIcon'
import TheBurgerMenu from '@/components/burgerMenu/TheBurgerMenu'

export default {
  name: 'TheHeader',
  props: {
    isMobile: Boolean,
    isMobileNav: Boolean,
  },
  components: {
    HeaderProfile,
    VButton,
    ChangeServiceIcon,
    TheBurgerMenu,
  },
  mixins: [
    currentPageIsProjectPage,
    currentWorkspace,
  ],

  data () {
    return {
      modelValue: false,
      showMobileNav: false,
    }
  },

  methods: {
    mobileNavOpen () {
      this.showMobileNav = !this.showMobileNav
    },
  },

  computed: {
    title () {
      return this.$t(this.$route.meta.title || '')
    },
    paidStatus () {
      return this.currentWorkspace.paid
    },
    showProTariffButton () {
      return !this.currentWorkspace.paid &&
        this.currentWorkspace.iAmAdmin
    },
    linkForButtonUpgrade () {
      return {
        name: 'ws-settings-workspace-billing',
        params: {
          wsId: this.currentWorkspace.id,
        },
      }
    },
  },

  i18n: {
    messages: {
      'en': {
        'Upgrade to PRO': 'Upgrade to PRO',
        Security: 'Security',
        Display: 'Display',
      },
      'ru': {
        'Upgrade to PRO': 'Улучшить до PRO',
        Security: 'Безопасность',
        Display: 'Дисплей',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  z-index: 12;
  display: grid;
  grid-template-columns: 1fr max-content;
  position: relative;
  height: $headerInnerHeight;
  box-sizing: content-box;
  border-bottom: 1px solid $borderColor;
  background-color: $lightThemeBackground2;

  @media (max-width: 800px) {
    grid-template-columns: auto max-content;
  }

  @include dark-theme {
    background: $darkThemeBgTwo;
    border-bottom: 1px solid $darkThemeBorderDark;
  }

  &__side {
    display: flex;
    align-items: center;
    width: 100%;

    &_right {
      justify-content: flex-end;
      padding: 0 10px;
      width: auto;
      border-left: 1px solid $borderColor;

      @include dark-theme {
        border-left-color: $darkThemeBorderDark;
      }
    }
  }

  &__button-upgrade {
    margin: 0 20px 0 auto;
    padding: 5px 8px;
    width: 100%;
    max-width: max-content;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    border-radius: 2px;
  }

  &__panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 59px;
    width: 60px;
    background-color: #FFF;
    border-right: 1px solid $borderGrey;
  }

  &__title {
    display: inline-flex;
    align-items: center;
    margin-left: $headerIconMarginLeft;
    font-weight: 600;
  }

  &__logo {
    position: absolute;
    left: 50%;
    display: flex;
    flex: 1 1 1px;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    margin-left: -10px;
    width: 20px;
    height: 59px;

    svg {
      width: 20px;
      height: 15px;

      path {
        stroke: #CCCED3;

        @include dark-theme {
          stroke: #2a2c30;
        }
      }
    }
  }
}

.meditation__link {
  &:hover {
    svg {
      path {
        fill: #c6c6c6;
      }
    }
  }
}

.logo {
  filter: drop-shadow(1px 1px 1px #666);
}
</style>
