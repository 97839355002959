<template>
  <div class="hamburger-wrap ml-2 mr-0">
    <button class="hamburger"
            :class="{'hamburger_active' : mobileNavOpen}"
            type="button"
            @click.stop="mobileNavOpen = !mobileNavOpen"
    >
      <span class="hamburger__line"></span>
      <span class="hamburger__middle"></span>
      <span class="icon-bar hamburger__line"></span>
    </button>

    <div class="container-fluid h-auto dropdown-wraper" id="app">

      <div class="row dropdown flex-column"
           v-if="mobileNavOpen"
           :class="{ 'dropdown-after' : mobileNavOpen }"
      >
        <!--           v-click-outside="mobileNavOpen = !mobileNavOpen"-->
        <ul class="navlist">
          <li class="navlistitem mt-4"
              v-for="navLink in navLinks"
          >
            <router-link :to="navLink.href"
                         @click="mobileNavOpen = !mobileNavOpen"
            >
              {{ $t(navLink.value) }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheBurgerMenu',
  data () {
    return {
      navLinks: {
        robots: {
          href: '/',
          value: 'Robots',
        },
        groups: {
          href: '/groups/',
          value: 'Groups',
        },
        advisors: {
          href: '/advisors/',
          value: 'Advisors',
        },
      },
      mobileNavOpen: false,
    }
  },
  watch: {
    $route () {
      this.mobileNavOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
.hamburger-wrap {
  width: auto;
  height: 100%;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  align-items: center;
}

.hamburger-wrap {
  float: right;
  justify-content: flex-end;
}

.hamburger {
  width: 45px;
  height: 45px;
  border-radius: 4px;
}

.hamburger_active {
  transform: rotate(90deg);
}

.hamburger:focus {
  outline: none;
}

.hamburger__line,
.hamburger__middle {
  display: block;
  width: 30px;
  height: 3px;
  border-radius: 2px;
  background-color: #000000;
  margin-top: 7px;
  margin-bottom: 7px;
}

.dropdown {
  margin-top: -20px;
  height: 0px;
  background-color: lightgreen;
  transition: height 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.dropdown-after {
  height: calc(100vh - 50px);
  transition: height 0.2s ease;
}

.dropdown-wraper {
  position: absolute;
  top: 120%;
}

.dropdown {
  height: 0px;
  background-color: white;
    /*#CFCFCF;*/
  box-shadow: 0 0 13px 0 rgba(48, 48, 48, 0.27);
  border-radius: 4px;
  transition: height 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: start;
  overflow: hidden;
}

.dropdown-after {
  height: auto;
/*calc(100vh - 50px)*/
  transition: height 0.2s ease;
}

.navlist {
  list-style: none;
}

.navlistitem {
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
}

.navlistitem a {
  color: #261E75;
}
</style>
