<template>
  <div class="languages">
    <div
      v-for="(lang, index) of languages"
      class="language"
      :class="{ language_active: lang == $i18n.locale }"
      :key="index"
      @click="change(lang)"
    >
      {{ langName(lang) }}
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'MenuLanguage',

  computed: {
    languages () {
      return {
        'EN': 'en',
        'RU': 'ru',
      }
    },
  },

  methods: {
    ...mapActions('general', [
      'setLanguage',
    ]),

    ...mapActions('user', [
      'setUserLanguage',
    ]),

    langName (lang) {
      return lang.split('-')[0]
    },
    change (lang) {
      this.setLanguage(lang)
      this.setUserLanguage(lang)
    },
  },
}
</script>

<style lang="scss" scoped>
// TODO: привязать селекторы через миксин
.dark-theme {
  .languages {
    .language {
      color: $darkThemeText;

      &_active {
        border-color: $darkThemeBlue;
      }
    }
  }
}

.languages {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;

  .language {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 25px;
    color: #2c2c2c;
    text-decoration: none;
    margin: 7px;
    cursor: pointer;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 4px;

    &_active {
      border-color: $blue;
      pointer-events: none;
    }
  }
}
</style>
