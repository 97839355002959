export const projectRoutes = [
  'project-calendar',
  'project-list',
  'project-board',
  'project-board-view',
  'project-ideas',
  'project-idea',
  'project-embeds',
  'project-embed',

  'ws-project-calendar',
  'ws-project-list',
  'ws-project-board',
  'ws-project-board-view',
  'ws-project-ideas',
  'ws-project-idea',
  'ws-project-embeds',
  'ws-project-embed',
]
