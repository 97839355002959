import { mapState } from 'vuex'
import { projectRoutes } from '@/utils/constants/projectView'

export const currentPageIsProjectPage = {
  computed: {
    currentPageIsProjectPage () {
      return projectRoutes.includes(this.$route.name)
    },
  },
}

export const viewingProjectId = {
  computed: {
    viewingProjectId () {
      return this.$route.params.id
        ? +this.$route.params.id
        : null
    },
  },
}

export const viewingProject = {
  computed: {
    ...mapState('project', [
      'projects',
    ]),

    viewingProject () {
      return this.projects.find(project => project.id === +this.$route.params.id)
    },
  },
}
